import {Icon, IconProps} from '@chakra-ui/react';

export function EarnCoinsIcon(props: IconProps) {
  return (
    <Icon width="29px" height="29px" viewBox="0 0 29 29" fill="none" color="white" {...props}>
      <circle cx="14.5" cy="14.5" r="14" stroke="currentColor" />
      <path
        d="M18 16.6989C18 17.4273 17.7403 18.045 17.2208 18.5521C16.71 19.0592 15.9654 19.3543 14.987 19.4372V21H14.1558V19.4372C13.5238 19.4004 12.9221 19.2759 12.3506 19.0638C11.7879 18.8426 11.3377 18.566 11 18.234L11.4805 17.1553C11.7922 17.4596 12.1861 17.7131 12.6623 17.916C13.1385 18.1096 13.6364 18.2248 14.1558 18.2617V15.0532C13.5498 14.8965 13.0433 14.7259 12.6364 14.5415C12.2381 14.3571 11.9004 14.0851 11.6234 13.7255C11.355 13.366 11.2208 12.8957 11.2208 12.3149C11.2208 11.5865 11.4675 10.9688 11.961 10.4617C12.4632 9.95461 13.1948 9.65957 14.1558 9.5766V8H14.987V9.56277C15.4805 9.58121 15.9567 9.66418 16.4156 9.8117C16.8831 9.95922 17.2857 10.1574 17.6234 10.4064L17.1948 11.5128C16.4935 11.0426 15.7576 10.7844 14.987 10.7383V13.9606C15.619 14.1266 16.1385 14.3018 16.5455 14.4862C16.9524 14.6613 17.2944 14.9287 17.5714 15.2883C17.8571 15.6387 18 16.1089 18 16.6989ZM12.4935 12.2596C12.4935 12.656 12.6364 12.9649 12.9221 13.1862C13.2078 13.4074 13.619 13.5918 14.1558 13.7394V10.7521C13.6017 10.8259 13.1861 11.0011 12.9091 11.2777C12.632 11.545 12.4935 11.8723 12.4935 12.2596ZM14.987 18.2479C15.5671 18.1926 16 18.0358 16.2857 17.7777C16.5801 17.5103 16.7273 17.1784 16.7273 16.7819C16.7273 16.3762 16.5758 16.0674 16.2727 15.8553C15.9784 15.634 15.5498 15.4404 14.987 15.2745V18.2479Z"
        fill="currentColor"
      />
    </Icon>
  );
}
