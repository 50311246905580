import {Icon, IconProps} from '@chakra-ui/react';

export function StoreIcon(props: IconProps) {
  return (
    <Icon width="22px" height="32px" viewBox="0 0 22 32" fill="none" color="white" {...props}>
      <path d="M21 31H1V8.96875H21V31ZM1.86957 30.0625H20.1304V9.90625H1.86957V30.0625Z" fill="currentColor" />
      <path
        d="M16.5 12.25H15.5V6.15625C15.5 3.83031 13.4815 1.9375 11 1.9375C8.5185 1.9375 6.5 3.83031 6.5 6.15625V12.25H5.5V6.15625C5.5 3.31328 7.9675 1 11 1C14.0325 1 16.5 3.31328 16.5 6.15625V12.25Z"
        fill="currentColor"
      />
      <path
        d="M21 31H1V8.96875H21V31ZM1.86957 30.0625H20.1304V9.90625H1.86957V30.0625Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M16.5 12.25H15.5V6.15625C15.5 3.83031 13.4815 1.9375 11 1.9375C8.5185 1.9375 6.5 3.83031 6.5 6.15625V12.25H5.5V6.15625C5.5 3.31328 7.9675 1 11 1C14.0325 1 16.5 3.31328 16.5 6.15625V12.25Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </Icon>
  );
}
