import {Icon, IconProps} from '@chakra-ui/react';

export function AboutUsIcon(props: IconProps) {
  return (
    <Icon width="29px" height="27px" viewBox="0 0 29 27" fill="none" color="white" {...props}>
      <path
        d="M15.2319 1.14554C14.9885 0.954741 14.6529 0.954741 14.4095 1.14554L1.25074 11.6726C0.967832 11.9028 0.921776 12.3108 1.14547 12.6002C1.37575 12.8832 1.78367 12.9292 2.07317 12.7055L2.3166 12.5016V25.3445C2.3166 25.7064 2.61268 26.0024 2.97454 26.0024H12.1857H17.4492H26.6603C27.0222 26.0024 27.3182 25.7064 27.3182 25.3445V12.5016L27.5683 12.6989C27.6867 12.7976 27.8314 12.8437 27.9762 12.8437C28.167 12.8437 28.3578 12.7581 28.4894 12.5937C28.7131 12.3108 28.667 11.8962 28.3841 11.666L15.2319 1.14554ZM16.7912 24.6866H12.8436V19.4231H16.7912V24.6866ZM26.0024 24.6866H18.1071V18.7651C18.1071 18.4033 17.811 18.1072 17.4492 18.1072H12.1857C11.8238 18.1072 11.5277 18.4033 11.5277 18.7651V24.6866H3.63248V11.4489L14.8174 2.5009L26.0024 11.4489V24.6866Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </Icon>
  );
}
