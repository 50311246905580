import {Icon, IconProps} from '@chakra-ui/react';

export const NewsIcon = (props: IconProps) => (
  <Icon width="22px" height="23px" viewBox="0 0 22 23" fill="none" {...props}>
    <path
      d="M0.518186 9.61123L4.65205 9.61123L4.65205 0.516734C4.65205 0.206695 4.85874 1.42751e-06 5.16878 1.4004e-06L20.6708 4.51742e-08C20.9808 1.80697e-08 21.1875 0.206693 21.1875 0.516733L21.1875 19.6358C21.1875 21.0827 20.0507 22.2195 18.6038 22.2195L2.84348 22.2195C1.29329 22.2195 0.00145332 20.9277 0.00145319 19.3775L0.00145238 10.128C0.00145236 9.8696 0.208146 9.61123 0.518186 9.61123ZM20.154 19.6358L20.154 1.03347L5.68551 1.03347L5.68551 10.128L5.68552 18.6541L5.68552 19.3775C5.68552 20.0492 5.42715 20.721 5.01376 21.186L18.6038 21.186C19.4823 21.186 20.154 20.5143 20.154 19.6358ZM1.03492 19.3775C1.03492 20.3593 1.86169 21.1861 2.84348 21.1861C3.82528 21.1861 4.65205 20.3593 4.65205 19.3775L4.65205 18.6541L4.65205 10.6447L1.03492 10.6447L1.03492 19.3775Z"
      fill="currentColor"
    />
    <path
      d="M8.00909 3.15234L17.827 3.15234C18.1371 3.15234 18.3438 3.35904 18.3438 3.66908L18.3438 6.82115C18.3438 7.13119 18.1371 7.33788 17.827 7.33788L8.00909 7.33788C7.69906 7.33788 7.49236 7.13119 7.49236 6.82115L7.49236 3.66908C7.49236 3.41071 7.75073 3.15234 8.00909 3.15234ZM8.52583 6.35609L17.3103 6.35609L17.3103 4.23748L8.52583 4.23748L8.52583 6.35609ZM8.16411 9.45648L17.672 9.45648C17.982 9.45648 18.1887 9.66318 18.1887 9.97322C18.1887 10.2833 17.982 10.4899 17.672 10.4899L8.21579 10.49C7.90575 10.49 7.69906 10.2833 7.69906 9.97322C7.69906 9.66318 7.90575 9.45648 8.16411 9.45648ZM8.16411 12.5569L17.672 12.5569C17.982 12.5569 18.1887 12.7636 18.1887 13.0736C18.1887 13.3837 17.982 13.5903 17.672 13.5903L8.21579 13.5903C7.90575 13.5903 7.69906 13.3837 7.69906 13.0736C7.69906 12.7636 7.90575 12.5569 8.16411 12.5569ZM8.16411 15.6573L17.672 15.6573C17.982 15.6573 18.1887 15.864 18.1887 16.174C18.1887 16.484 17.982 16.6907 17.672 16.6907L8.21579 16.6907C7.90575 16.6907 7.69906 16.4841 7.69906 16.174C7.69906 15.864 7.90575 15.6573 8.16411 15.6573Z"
      fill="currentColor"
    />
    <path
      d="M17.827 3.15234L8.00909 3.15234C7.75073 3.15234 7.49236 3.41071 7.49236 3.66908L7.49236 6.82115C7.49236 7.13119 7.69906 7.33788 8.00909 7.33788L17.827 7.33788C18.1371 7.33788 18.3438 7.13119 18.3438 6.82115L18.3438 3.66908C18.3438 3.35904 18.1371 3.15234 17.827 3.15234Z"
      fill="currentColor"
    />
  </Icon>
);
