import {Icon, IconProps} from '@chakra-ui/react';

export const LogoutIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color="white" {...props}>
    <path
      d="M10 20C4.5 20 4 19 4 12C4 5 4.5 4 9.99999 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path d="M9 12H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />

    <path d="M16 16L20 12L16 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
